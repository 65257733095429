// MessageContext.js
import React, { createContext, useState, useContext } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());

    const newErrorMessage = (newMessage) => {
        setTimestamp(Date.now());
        setErrorMessage(newMessage);
        setTimeout(() => {
            setErrorMessage(null);
        }, 5000);
    };

    const newSuccessMessage = (newMessage) => {
        setTimestamp(Date.now());
        setSuccessMessage(newMessage);
        setTimeout(() => {
            setSuccessMessage(null);
        }, 5000);
    };

    return (
        <MessageContext.Provider value={{
            errorMessage, successMessage, timestamp,
            newErrorMessage, newSuccessMessage
        }}>
            {children}
        </MessageContext.Provider>
    );
};

export const useMessage = () => useContext(MessageContext);
