import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

const AlertStack = ({ successMessage, errorMessage, timestamp }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const time = 5000;

  useEffect(() => {
    setShowSuccess(false);
    if (successMessage) {
      setShowSuccess(true);
      const timer = setTimeout(() => setShowSuccess(false), time);
      return () => clearTimeout(timer);
    } else {
      setShowSuccess(false);
    }
  }, [successMessage, timestamp]);

  useEffect(() => {
    if (errorMessage) {
      setShowError(true);
      const timer = setTimeout(() => setShowError(false), time);
      return () => clearTimeout(timer);
    } else {
      setShowError(false);
    }
  }, [errorMessage, timestamp]);

  const alerts = (
    <Stack
      sx={{
        width: '50%',
        position: 'fixed',
        top: '10px',
        left: '25%',
        zIndex: 1000,
        pointerEvents: 'none'
      }}
      spacing={0.5}
    >
      <Fade in={showSuccess} timeout={500}>
        <Alert variant="filled" severity="success">
          {successMessage}
        </Alert>
      </Fade>
      <Fade in={showError} timeout={500}>
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Fade>
    </Stack>
  );

  return ReactDOM.createPortal(
    alerts,
    document.body
  );
};

export default AlertStack;
